import React from 'react';
import styled from 'styled-components';
import { Link } from "gatsby"
import {StaticImage} from "gatsby-plugin-image";


const About = ({data}) => {
    
    return <AboutContainer>
        <AboutH1Container>
            <AboutH1>{"About"}</AboutH1>
        </AboutH1Container>
        <TextContainer>
            <p> Hey there!  My name is Felipe Angelim, Senior Data Scientist at XP Inc., in Brazil.
            </p>
            <p>
            I’m in the intersection of maths and programming, having already built a data science infrastructure from ground-up for an asset management company in Brazil, using cutting-edge technologies such as <b>Kubernetes</b> and <b>Airflow</b> on Azure Cloud.

            </p>
            <p>
            My work experience also includes <b>time-series forecasting</b>, quantitative finance, pattern recognition, web-scraping, web development.
            </p>

            <p>Sometimes I write about machine-learning and programming on my <StyledLink to="/blog">blog</StyledLink></p>.


         </TextContainer>
    
    </AboutContainer>

}


const StyledLink = styled(Link)`

    color: #0c0c0c;
    display: inline-block;
    text-decoration: none;
    transition: all 0.01s ease 0s, left 0.01s ease 0s;
    position: relative;
    margin-top: 2vh;
    font-weight: normal;
    font-size: 20px;

    &:hover, &:focus {
        color: #00EBA3;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        bottom: -2px;
        width: 0px;
        height: 1px;
        margin: 5px 0 0;
        transition: all 0.01s ease-in-out;;
        transition-duration: 0.75s;
        opacity: 0;
        background-color: #00EBA3;
        
        width: 50%;
        opacity: 1;

    }

    &:before {
    left: 50%;
    }

    &:after {
    right: 50%;
    }

    &:hover {
        cursor: pointer;
    }
`

const AboutContainer = styled.div`
    height: fit-content;
    width: 100%;
    background: #eeeeee;
    
`;

const AboutH1 = styled.h1`
    color: #0c0c0c;
    font-style: "Inconsolata", sans-serif;
    padding-left: 20px;
    margin-bottom: 2px;
    font-weight: normal;

    @media screen and (max-width: 768px) {

        align-text: center;
    }
`

const TextContainer = styled.div`
    text-align: start;
    text-justify: inter-word;
    width: 60%;
    font-family: "Inconsolata", sans-serif;
    font-weight: normal;
    font-size: 20px;
    margin-left: 5vw;
    margin-top: 3rem;

    @media screen and (max-width: 768px) {

        
        width: 90%;
    }

`

const AboutH1Container = styled.div`
    border-color: #0c0c0c;
    border-bottom: 2px solid #0c0c0c;
    overflow: hidden;
    width: fit-content;
    margin-left: 5vw;
    position: relative; 

    :after {
        position: absolute;
        content: "";
        width: 50%;
        height: 1px;
        border-bottom: 2px #eeeeee
    }
`

const ImageContainer = styled.div`
    
    width: 40%;
    left: 0;
    flex-direction: column;
    border-radius: "200px 0px 0px 0px";
    margin-left: 10vw;
    @media screen and (max-width: 768px) {
        width: 90%;
        height: 30%;
        margin: 0;
        margin-top: 2vh;
        border-radius: "0px 0px 0px 0x";
        width: 100%;
        
    }
`

export default About;