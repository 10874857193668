import React from 'react';
import styled from 'styled-components';
import {ImArrowUp} from "react-icons/im";
import "@fontsource/inconsolata";
import {Link} from "gatsby";
import loadable from '@loadable/component';
import {StaticImage} from "gatsby-plugin-image";



const Hero = () => {
    return (
        <div>
            <HeroContainer>
                
                <HeroPhotoContainer>
                    
                    
                    <StaticImage src="../../images/iceland_curiosity.png" placeholder="none" style={
                        { borderRadius : "354.5px",
                          boxShadow : "0px 15px 10px 5px rgba(0, 0, 0, 0.20)"
                          
                          }}/>
                </HeroPhotoContainer>

                <HeroPhrase>
                    Curiosity-driven data science.
                </HeroPhrase>
                    
            </HeroContainer>
        </div>
    )
}



export default Hero

const HeroPhotoContainer = styled.div`
    border-radius : 354.5px;
    width: max(40vw, 300px) ;

    @media screen and (max-width: 768px) {
        width: 300px
    }

`

const HeroContainer = styled.div`
    background: var(--background-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    padding: 0 1 rem;
    position: relative;
    color: #fff;
    margin-top: -80px;

    @media screen and (max-width: 768px) {
     flex-direction: column;
  }
`

const HeroPhrase = styled.div`
    font-family: "Inconsolata", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 25px;
    margin-top: 5vh;
    color: #0c0c0c;

    @media screen and (max-width: 768px) {
        text-align: center;
        width: 90%;
    }
    
`

const HeroContent = styled.div`
    z-index: 3;
    height: calc(100vh - 80px);
    max-height: 100%;
    width: 100%;
    padding: 0rem calc(100vw - 1300px) /2;
`


const HeroItems = styled.div`
    position:absolute;
    background: transparent;
    display: flex;
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 100vh;
    max-height: 100%;
    padding-left: 8vw;
    padding-bottom: 3rem;
    color: #FFF;
    line-height: 1.1.%;
    font-family: "Roboto", sans-serif;
    z-index: 2; 
    margin-top: 5vh;  

    @media screen and (max-width: 768px) {
     width: 90%
  }

`

const HeroLine = styled.div`
    display: flex;
    flex-direction: row;

`
const Name = styled.h1`
    font-size: clamp(2.0rem, 5.5vw, 3.5rem);
    margin-bottom: 0;
    margin-top: 0;
    line-height: 50%;
    color: #00EBA3;
    font-weight: 550;
    font-family: "Inconsolata";
    z-index: 4;
`

const HeroH1 = styled.h1`
    font-size: clamp(2.1rem, 5vw, 3.5rem);  
    z-index: 4;
    margin-bottom: 0;
    font-weight: 600;
    font-family: "Roboto";
`

const HeroH2 = styled.h2`
    font-size: clamp(1rem, 2.4vw, 1.5rem);
    font-family: "Inconsolata";
    margin-bottom: 1.7rem;
    font-weight: lighter;
    display: inline;
    z-index: 4;
`

const HeroP  = styled.p`
    z-index: 4;
    font-size: clamp(0.5rem, 5vw, 1rem);
    margin-bottom: 2rem;
    font-family: "Inconsolata", sans-serif;
`

const InTouchLink = styled(Link)`
    color: #00EBA3;
    text-decoration: none;

    transition-duration: 0.5s;

    &:hover,  &:focus  {
        letter-spacing: 0.1rem;
    }
`
